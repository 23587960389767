import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DropDown, Divider, FormField } from 'components';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { getBuyerProfile } from 'store/selectors/buyers';
import Row from 'react-bootstrap/Row';
import { BuyerProfileContainer, TabItem, HeaderBoldText } from './buyerProfile.styled';
import BuyerTerms from './buyerTerms';
import BuyerContingencies from './buyerContingencies';
import Swal from 'sweetalert2';

function BuyerProfiles({ profiles }) {
  const dispatch = useDispatch();

  const selectedProfile = useSelector(getBuyerProfile);
  const [buyerProfile, setBuyerProfile] = useState();
  const [currentTab, setCurrentTab] = useState('terms');
  const [thisProfile, setThisProfile] = useState();
  const errors = {
    primaryBuyer: {},
    terms: {},
    contingencies: {},
    creditItems: {},
    costAllocations: {},
    services: {},
    otherTerms: {},
    review: {},
  };
  const [dirty, setDirty] = useState(false);

  const { isSuccess: isDeleteSuccess } = useSelector(BuyersAsyncActions.DeleteBuyerProfile.StatusSelector());

  useEffect(() => {
    return () => {
      dispatch(BuyersAsyncActions.FetchBuyerProfile.Actions.RESET());
      dispatch(BuyersAsyncActions.UpdateBuyerProfile.Actions.RESET());
      dispatch(BuyersAsyncActions.DeleteBuyerProfile.Actions.RESET());
    };
  }, [dispatch]);

  useEffect(() => {
    setThisProfile(selectedProfile);
  }, [selectedProfile]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setBuyerProfile();
      setThisProfile();
      setDirty(false);
    }
  }, [isDeleteSuccess]);

  const options = useMemo(() => {
    const options = new Set();
    profiles?.forEach((p) => {
      options.add({ id: p.id, name: p.label });
    });
    return [...options.values()];
  }, [profiles]);

  const fetchBuyerProfile = useCallback(
    (id) => dispatch(BuyersAsyncActions.FetchBuyerProfile.Actions.REQUEST(id)),
    [dispatch]
  );

  const handleProfileChange = (e) => {
    const { value } = e.target;
    setBuyerProfile(value);
    fetchBuyerProfile(value);
  };

  const onTermsStateChange = (e) => {
    const newDetails = { ...thisProfile.details, terms: e };
    const newProfile = { ...thisProfile, details: newDetails };
    setThisProfile(newProfile);
    setDirty(true);
  };

  const onContingenciesStateChange = (e) => {
    const newDetails = {
      ...thisProfile.details,
      contingencies: {
        ...thisProfile.details.contingencies,
        [e.field]: e.value,
      },
    };
    const newProfile = { ...thisProfile, details: newDetails };
    setThisProfile(newProfile);
    setDirty(true);
  };

  const onProfileNameChange = (newName) => {
    const newDetails = {
      ...thisProfile,
      name: newName.value,
    };
    setThisProfile(newDetails);
  };

  const onUpdateProfile = () => {
    dispatch(BuyersAsyncActions.UpdateBuyerProfile.Actions.REQUEST(thisProfile));
  };

  const onDeleteProfile = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure you want to delete this profile?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Delete',
    });
    if (isConfirmed) {
      dispatch(BuyersAsyncActions.DeleteBuyerProfile.Actions.REQUEST(thisProfile.id));
    }
  };

  return (
    <BuyerProfileContainer>
      <Row className='mt-2'>
        <DropDown
          label='Select Profile'
          groupName='buyerprofiles'
          selectedOption={buyerProfile ? buyerProfile : 'select'}
          onStateChanged={handleProfileChange}
          items={options}
        />
      </Row>
      <Divider />
      {buyerProfile && (
        <>
          <div className='mt-4 mb-2 d-flex justify-content-between align-items-center'>
            <HeaderBoldText>Active Profile</HeaderBoldText>
            <Button variant='outline-danger' size='sm' onClick={() => onDeleteProfile()}>
              Delete Profile
            </Button>
          </div>
          <Divider />
          <Row className='my-4'>
            <FormField
              type='text'
              fieldId='name'
              label='Profile Name'
              initialValue={thisProfile?.name}
              onStateChanged={onProfileNameChange}
            />
          </Row>
          <Divider />
          <Modal.Header className='p-0 pt-2 justify-content-start'>
            <TabItem active={currentTab === 'terms'} onClick={() => setCurrentTab('terms')}>
              Terms
            </TabItem>
            <TabItem active={currentTab === 'contingencies'} onClick={() => setCurrentTab('contingencies')}>
              Contingencies
            </TabItem>
          </Modal.Header>
          {currentTab === 'terms' && (
            <BuyerTerms selectedProfile={thisProfile} onStateChange={onTermsStateChange} errors={errors} />
          )}
          {currentTab === 'contingencies' && (
            <BuyerContingencies
              contingencies={thisProfile.details.contingencies}
              stateData={() => {}}
              onChange={onContingenciesStateChange}
              onStateDataChange={onContingenciesStateChange}
              errors={errors}
            />
          )}
          <Divider />
          {dirty && (
            <div className='d-flex  justify-content-end'>
              <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdateProfile()}>
                Update Profile
              </Button>
            </div>
          )}
        </>
      )}
    </BuyerProfileContainer>
  );
}

export default BuyerProfiles;
