import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getOfferTitle,
  getOfferIdStr,
  URLUtils,
  getOfferHeaderBackground,
  fixBootstrapModal,
  restoreBootstrapModal,
} from 'utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Icon, ButtonWrapper, FormattedMessage, PopoverTooltip } from 'components';
import {
  OfferCardContainer,
  BoldText,
  LightText,
  OfferCardHeader,
  HeaderBoldText,
  HeaderLightText,
  ColHeader,
  ColValue,
  OfferCardBody,
} from './offerReviewCard.styled';
import moment from 'moment';
import { formatCurrency } from 'utils';
import { isNil } from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { OffersAsyncActions } from 'store/actions/offers';
import { useDispatch, useSelector } from 'react-redux';
import OfferFooter from 'containers/ReviewOfferPage/offerFooter';
import CounteringOfferPopup from 'containers/ReviewOfferPage/counteringOfferPopup';
import { useAuthUser } from 'hooks';
import { getCounterOffer } from 'store/selectors/offers';
import routeConstants from 'containers/App/routeConstants';
import { useHistory } from 'react-router';
import OfferExpandModal from 'containers/ReviewOfferPage/offerExpandModal';

const MySwal = withReactContent(Swal);

function OfferReviewCard({ offer, listing, actions, highestOffer, offerCount, globalCounterDetails, onClose }) {
  const { originalOffer, counterOffers } = offer;
  const isMultipleCounterOffer = offerCount > 1;
  const offerToShow = counterOffers?.length > 0 ? counterOffers[0] : originalOffer;
  const globalCounter = offerToShow.offerType === 'GLOBAL_COUNTER' ? offer.originalOffer : globalCounterDetails;

  const { user, isSellerAgent } = useAuthUser({ listing });
  const dispatch = useDispatch();
  const history = useHistory();

  const newCounter = useSelector(getCounterOffer);
  const { isSuccess: updateStateSuccess, error: updateStateError } = useSelector(
    OffersAsyncActions.UpdateState.StatusSelector()
  );
  const { isSuccess: createCounterSuccess, error: createCounterError } = useSelector(
    OffersAsyncActions.CreateCounterOffer.StatusSelector()
  );
  const { isSuccess: updateCounterSuccess, error: updateCounterError } = useSelector(
    OffersAsyncActions.UpdateCounterOffer.StatusSelector()
  );

  const [showCounter, setShowCounter] = useState(false);
  const [isEditCounter, setIsEditCounter] = useState(false);
  const [nextState, setNextState] = useState(null);
  const [showExpandModal, setShowExpandModal] = useState(false);

  const isCalifornia = listing?.address?.state === 'CA';

  useEffect(() => {
    return () => {
      dispatch(OffersAsyncActions.UpdateState.Actions.RESET());
      dispatch(OffersAsyncActions.CreateCounterOffer.Actions.RESET());
      dispatch(OffersAsyncActions.UpdateCounterOffer.Actions.RESET());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateStateSuccess) {
      MySwal.fire({
        title: 'Your offer has been updated',
      }).then(() => {
        onClose();
      });
    }
  }, [updateStateSuccess, onClose]);

  useEffect(() => {
    if (updateStateError) {
      setNextState(null);
    }
  }, [updateStateError]);

  useEffect(() => {
    if (createCounterSuccess || updateCounterSuccess) {
      MySwal.fire({
        title: <FormattedMessage id='offer.counterOfferSuccess.label' />,
        html: (
          <FormattedMessage
            id={
              newCounter?.state === 'PENDING_SELLER_AGENT_RESPONSE'
                ? 'offer.counterOfferSuccess.seller.description'
                : 'offer.counterOfferSuccess.buyer.description'
            }
          />
        ),
        icon: 'success',
        allowOutsideClick: false,
      }).then(() => {
        onClose();
      });
    }
    setShowCounter(false);
    setNextState(null);
    setIsEditCounter(false);
  }, [createCounterSuccess, createCounterError, updateCounterSuccess, updateCounterError, newCounter, onClose]);

  const createCounterOffer = useCallback(
    (counterOffer) => dispatch(OffersAsyncActions.CreateCounterOffer.Actions.REQUEST(counterOffer)),
    [dispatch]
  );

  const updateCounterOffer = useCallback(
    (counterOffer) => dispatch(OffersAsyncActions.UpdateCounterOffer.Actions.REQUEST(counterOffer)),
    [dispatch]
  );

  const handleViewRpa = (url) => {
    URLUtils.openDocument(url);
  };

  const getName = (userBuyer) => {
    return `${userBuyer.firstName.charAt(0)}. ${userBuyer.lastName}`;
  };

  const getPaymentMethod = (terms) => {
    if (isNil(terms.loan)) return 'Not specified';
    return terms.loan ? (terms.loanType ? terms.loanType : 'Loan') : 'Cash';
  };

  const updateOfferState = useCallback(
    (offerId, offerState) => dispatch(OffersAsyncActions.UpdateState.Actions.REQUEST(offerId, offerState)),
    [dispatch]
  );

  const handleAccept = async (offerShowing, newState) => {
    const { isConfirmed } = await MySwal.fire({
      title: (
        <FormattedMessage
          id={
            offerShowing.offerType === 'SELLER_MULTIPLE_COUNTER' && newState !== 'ACCEPTED'
              ? 'offer.select.label'
              : 'offer.accept.label'
          }
        />
      ),
      html:
        offerShowing.offerType === 'SELLER_MULTIPLE_COUNTER' ? (
          <>
            <FormattedMessage
              id={newState === 'ACCEPTED' ? 'offer.accept.SMCO.description' : 'offer.accept.SMCOBuyer.description'}
            />
            <PopoverTooltip contentId={'tooltip.offer.accept.SMCO.description'} />
          </>
        ) : (
          <FormattedMessage id='offer.accept.description' />
        ),
      icon: 'success',
      showCloseButton: true,
      confirmButtonText: 'OK',
    });
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleReject = async (offerShowing, newState) => {
    fixBootstrapModal();
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id='offer.reject.label' />,
      html: <FormattedMessage id='offer.reject.description' />,
      input: 'textarea',
      inputLabel: 'Description Below',
      inputPlaceholder: 'Type your message here...',
      showCancelButton: true,
      confirmButtonText: 'Reject',
    });
    restoreBootstrapModal();
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleCancel = async (offerShowing, newState) => {
    fixBootstrapModal();
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id='offer.cancel.label' />,
      html: <FormattedMessage id='offer.cancel.description' />,
      showCancelButton: true,
      confirmButtonText: 'Continue',
    });
    restoreBootstrapModal();
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleApprove = async (offerShowing, newState) => {
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id='offer.approve.label' />,
      text: 'Upon approving the acceptance you will be asked to digitally sign the agreement.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Approve',
    });
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleRescind = async (offerShowing, newState, rescindId) => {
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id={`offer.${rescindId}.label`} />,
      html: <FormattedMessage id={`offer.${rescindId}.description`} />,
      icon: 'error',
      showCloseButton: true,
      confirmButtonText: 'Rescind',
    });
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleEdit = async (nextState) => {
    const { isConfirmed } = await MySwal.fire({
      title: 'You are about to edit your offer',
      text: 'The seller will not be able to accept or counter your offer while you are editing this offer so proceed rapidly.',
      icon: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Continue',
    });
    if (!isConfirmed) {
      return;
    }
    if (
      nextState === 'PENDING_SELLER_COUNTER_REVIEW' ||
      nextState === 'PENDING_BUYER_COUNTER_REVIEW' ||
      (counterOffers?.length > 0 &&
        (counterOffers[0].offerType === 'BUYER_COUNTER' ||
          counterOffers[0].offerType === 'BUYER_MULTIPLE_COUNTER' ||
          counterOffers[0].offerType === 'SELLER_COUNTER'))
    ) {
      setShowCounter(true);
      setNextState(nextState);
      setIsEditCounter(true);
    } else if (nextState === 'DRAFT' || nextState === 'LIVE_EDIT') {
      updateOfferState(originalOffer.id, nextState);
      history.push(routeConstants.OFFERS_PATH + `/${offer.originalOffer.id}`, {
        listing,
        highestOffer,
        offerCount,
      });
    }
  };

  const handleCounter = (newState) => {
    setShowCounter(true);
    setNextState(newState);
  };

  const handleCloseCounter = () => {
    setShowCounter(false);
  };

  const newCounterOfferType = useMemo(() => {
    return actions?.find(({ action }) => action === 'COUNTER')?.newCounterType;
  }, [actions]);

  const getActionButtons = () => {
    let onAccept = null;
    let onReject = null;
    let onApprove = null;
    let onRescind = null;
    let onCancel = null;
    let onCounter = null;
    let onEdit = null;
    let onBuyerRescind = null;

    let isMultipleCounter = false;
    let isFinalCounter = false;

    const isBuyerAgent = originalOffer ? user?.id === offer.originalOffer.agent.id : user?.id === offer.agent.id;

    actions.forEach(({ action, transitionState, newCounterType }) => {
      if (action === 'EDIT') {
        onEdit = () => handleEdit(transitionState);
      } else if (action === 'RESCIND') {
        if (isSellerAgent) {
          onRescind = () => handleRescind(offerToShow, transitionState, 'rescind');
        } else if (isBuyerAgent) {
          onBuyerRescind = () => handleRescind(offerToShow, transitionState, 'buyerRescind');
        }
      } else if (action === 'ACCEPT') {
        onAccept = () => handleAccept(offerToShow, transitionState);
      } else if (action === 'CANCEL') {
        onCancel = () => handleCancel(offerToShow, transitionState);
      } else if (action === 'REJECT') {
        onReject = () => handleReject(offerToShow, transitionState);
      } else if (action === 'APPROVE') {
        onApprove = () => handleApprove(offerToShow, transitionState);
      } else if (action === 'COUNTER') {
        onCounter = () => handleCounter(transitionState);
        isMultipleCounter = newCounterType === 'SELLER_MULTIPLE_COUNTER';
        isFinalCounter = newCounterType === 'SELLER_FINAL_COUNTER';
      }
    });
    return (
      <div className='pb-2 pe-2'>
        <OfferFooter
          key={offer?.id}
          offer={offerToShow}
          globalCounter={null}
          isGlobalCounter={false}
          onAccept={onAccept}
          onReject={onReject}
          onCancel={onCancel}
          onRescind={onRescind}
          onApprove={onApprove}
          onEdit={onEdit}
          onCounter={onCounter}
          isMultipleCounter={isMultipleCounter}
          isFinalCounter={isFinalCounter}
          onBuyerRescind={onBuyerRescind}
          hideOfferInfo={true}
        />
      </div>
    );
  };

  const handleSend = (counterOffer) => {
    const counterRequest = {
      ...counterOffer,
      state: nextState,
    };
    if (!isEditCounter) {
      counterRequest.id = null;
      createCounterOffer(counterRequest);
    } else {
      updateCounterOffer(counterRequest);
    }
  };

  return (
    <OfferCardContainer>
      <OfferCardHeader className='d-flex flex-row' background={getOfferHeaderBackground(offerToShow?.offerType)}>
        {offerToShow.rpaUrl && (
          <ButtonWrapper onClick={() => handleViewRpa(offerToShow.rpaUrl)}>
            <Icon name='offer' className='m-auto' />
          </ButtonWrapper>
        )}
        <div>
          {offerToShow.primaryBuyer && (
            <HeaderBoldText className='ms-2'>
              {offerToShow.primaryBuyer.ownerType === 'INDIVIDUAL'
                ? getName(offerToShow.primaryBuyer.user)
                : offerToShow.primaryBuyer.orgName}
            </HeaderBoldText>
          )}
          {offerToShow.secondaryBuyers?.length > 0 && offerToShow.secondaryBuyers[0]?.user && (
            <HeaderBoldText>{getName(offerToShow.secondaryBuyers[0].user)}</HeaderBoldText>
          )}
        </div>
        <div className='ms-auto'>
          <HeaderLightText>{getOfferTitle(offerToShow.offerType)}</HeaderLightText>
          <HeaderLightText>[ID# {getOfferIdStr(offerToShow.id)}]</HeaderLightText>
          <Icon
            className='mt-2'
            name='pencil-square'
            color='white'
            size={24}
            onClick={() => setShowExpandModal(true)}
          />
        </div>
      </OfferCardHeader>
      <OfferCardBody>
        <Row className='mt-2'>
          <Col>
            <ColHeader>
              <LightText>Net Amount</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{formatCurrency(offerToShow.terms.offerAmount, 0)}</BoldText>
            </ColValue>
          </Col>
          <Col>
            <ColHeader>
              <LightText>Closing Date</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>
                {offerToShow.terms.useDaysToClose
                  ? offerToShow.terms.daysToClose + ' Days'
                  : moment(offerToShow.terms?.exactClosingDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
              </BoldText>
            </ColValue>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <ColHeader>
              <LightText>Earnest Money</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{formatCurrency(offerToShow.terms.earnestDepositDollar, 0)}</BoldText>
            </ColValue>
          </Col>
          {!isCalifornia && (
            <Col>
              <ColHeader>
                <LightText>Day(s) to deliver to escrow</LightText>
              </ColHeader>
              <ColValue>
                <BoldText>{offerToShow.terms.earnestDepositDays}</BoldText>
              </ColValue>
            </Col>
          )}
        </Row>
        <Row className='mt-2'>
          <Col>
            <ColHeader>
              <LightText>Additional Deposit</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{formatCurrency(offerToShow.terms.increasedDepositDollar, 0)}</BoldText>
            </ColValue>
          </Col>
          <Col>
            <ColHeader>
              <LightText>Day(s) to deliver to escrow</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{offerToShow.terms.increasedDepositDays}</BoldText>
            </ColValue>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <ColHeader>
              <LightText>Purchase Method</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{getPaymentMethod(offerToShow.terms)}</BoldText>
            </ColValue>
          </Col>
          <Col>
            <ColHeader>
              <LightText>Down Payment</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{formatCurrency(offerToShow.terms.downPaymentDollar, 0)}</BoldText>
            </ColValue>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <ColHeader>
              <LightText>Loan Amount</LightText>
            </ColHeader>
            <ColValue>
              <BoldText>{formatCurrency(offerToShow.terms.loanAmountDollar, 0)}</BoldText>
            </ColValue>
          </Col>
        </Row>
      </OfferCardBody>
      {getActionButtons()}
      <Offcanvas
        placement='end'
        show={showCounter}
        scroll
        className='p-0'
        style={{ width: 'fit-content' }}
        onHide={handleCloseCounter}
      >
        <Offcanvas.Body className='p-0' style={{ overflowX: 'hidden' }}>
          <CounteringOfferPopup
            originOffer={
              offer.offerType === 'GLOBAL_COUNTER' || !counterOffers || counterOffers.length === 0
                ? originalOffer
                : counterOffers[0]
            }
            listing={listing}
            offerType={newCounterOfferType}
            onSend={handleSend}
            onClose={handleCloseCounter}
            isMultipleCounterOffer={isMultipleCounterOffer}
          />
        </Offcanvas.Body>
      </Offcanvas>
      {showExpandModal && (
        <OfferExpandModal
          show={showExpandModal}
          offers={[offerToShow]}
          listing={listing}
          globalCounter={globalCounter}
          onClose={() => setShowExpandModal(false)}
        />
      )}
    </OfferCardContainer>
  );
}

OfferReviewCard.propTypes = {
  offer: PropTypes.object,
  listing: PropTypes.object,
  actions: PropTypes.array,
  highestOffer: PropTypes.number,
  offerCount: PropTypes.number,
  globalCounterDetails: PropTypes.object,
};

export default OfferReviewCard;
