import { all, takeLatest } from 'redux-saga/effects';
import { BuyersAsyncActions } from 'store/actions/buyers';
import list from './list';
import fetch from './fetch';
import fetchBuyerOffers from './fetchBuyerOffers';
import fetchBuyerProfile from './fetchBuyerProfile';
import fetchBuyerProfiles from './fetchBuyerProfiles';
import updateBuyerProfile from './updateBuyerProfile';
import createBuyerProfile from './createBuyerProfile';
import deleteBuyerProfile from './deleteBuyerProfile';

export default function* root() {
  const {
    ListBuyers,
    FetchBuyer,
    FetchBuyerOffers,
    FetchBuyerProfile,
    FetchBuyerProfiles,
    UpdateBuyerProfile,
    CreateBuyerProfile,
    DeleteBuyerProfile,
  } = BuyersAsyncActions;
  yield all([
    takeLatest(ListBuyers.Types.REQUEST, list),
    takeLatest(FetchBuyer.Types.REQUEST, fetch),
    takeLatest(FetchBuyerOffers.Types.REQUEST, fetchBuyerOffers),
    takeLatest(FetchBuyerProfile.Types.REQUEST, fetchBuyerProfile),
    takeLatest(FetchBuyerProfiles.Types.REQUEST, fetchBuyerProfiles),
    takeLatest(UpdateBuyerProfile.Types.REQUEST, updateBuyerProfile),
    takeLatest(CreateBuyerProfile.Types.REQUEST, createBuyerProfile),
    takeLatest(DeleteBuyerProfile.Types.REQUEST, deleteBuyerProfile),
  ]);
}
