import { call, put, select } from 'redux-saga/effects';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* deleteBuyerProfile(action) {
  const {
    DeleteBuyerProfile: {
      Actions: { SUCCESS, FAILURE },
    },
    FetchBuyerProfiles: {
      Actions: { SUCCESS: FetchBuyerProfilesSuccess },
    },
  } = BuyersAsyncActions;
  const {
    payload: { buyerProfileId },
  } = action;

  try {
    yield call(apiCall, `/buyerProfiles/${buyerProfileId}`, 'delete');
    const buyerProfiles = yield select((state) => state.buyers.buyerProfiles);
    const newProfiles = {
      ...buyerProfiles,
      profiles: buyerProfiles.profiles.filter((profile) => profile.id !== buyerProfileId),
    };
    yield put(FetchBuyerProfilesSuccess(newProfiles));
    yield put(SUCCESS());
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default deleteBuyerProfile;
